import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DashboardServiceProtocol } from '../dashboard/DashboardService';
import { DependencyContainer } from '../../DependencyContainer';
import {
  Backdrop,
  CircularProgress,
  Container,
  makeStyles,
} from '@material-ui/core';
import { EmailUserInfoCard } from './components/EmailUserInfoCard';
import { useSetRecoilState } from 'recoil';
import { alertAtom, searchTextAtom } from '../../state';
import {
  AlertType,
  EmailUser,
  PaymentAvailablePlans,
  Space,
  StatePaymentInfo,
} from '../../types';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  tableContainer: {
    marginTop: '30px',
  },
  table: {
    minWidth: 650,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },

  card: {
    display: 'flex',
    minWidth: 275,
    backgroundColor: '#fff',
    marginTop: '20px',
  },
  cardBullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  cardTitle: {
    fontSize: 14,
  },
  avatar: {
    width: 151,
    minHeight: '100px',
  },

  badges: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    width: '400px',
    margin: '20px auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const service: DashboardServiceProtocol = new DependencyContainer()
  .dashboardService;

const UserContainer = () => {
  const classes = useStyles();
  let { email } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [spaces, setSpaces] = useState<Space[]>([]);
  const [deletedSpaces, setDeletedSpaces] = useState<Space[]>([]);
  const [emailUser, setEmailUser] = useState<EmailUser | null>(null);
  const setSearchText = useSetRecoilState<string>(searchTextAtom);
  const setAlert = useSetRecoilState<AlertType>(alertAtom);

  const [paymentInfo, setPaymentInfo] = useState<StatePaymentInfo>({
    purchasedVia: '',
    plan: 'free',
  });

  const toggleElite = (userId: string) => {
    const strategy =
      paymentInfo.plan === PaymentAvailablePlans.Free
        ? 'addSubscription'
        : 'cancelSubscription';
    service
      .changeSubscription(strategy, userId)
      .then(() => {
        setPaymentInfo((p) => ({
          ...p,
          plan:
            p.plan === PaymentAvailablePlans.Free
              ? PaymentAvailablePlans.Elite
              : PaymentAvailablePlans.Free,
        }));
        setAlert({
          type: 'success',
          message: 'Subscription status has been changed',
        });
      })
      .catch(() => {
        setAlert({
          type: 'error',
          message: 'Something went wrong',
        });
      });
  };

  const loadSpaces = async (emailUser: EmailUser) => {
    setLoading(true);
    try {
      const spaces = await service.getUserSpaces(emailUser._id);
      const deletedSpaces = await service.getUserDeletedSpaces(emailUser._id);
      setSpaces(spaces);
      setDeletedSpaces(deletedSpaces);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setSearchText(email);
    service
      .getUserByEmail(email)
      .then((r) => {
        setEmailUser(r);
        setError(false);
        const payment =
          r.payment_info && r.payment_info[0]
            ? r.payment_info[0]
            : {
                plan: 'free',
                purchased_via: '',
              };
        setPaymentInfo({
          plan: payment.plan,
          purchasedVia: payment.purchased_via,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setAlert({
          type: 'error',
          message: 'User not found',
        });
      });
  }, [email]);

  useEffect(() => {
    if (emailUser?._id) {
      loadSpaces(emailUser);
    }
  }, [emailUser]);

  return (
    <Container maxWidth="xl">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {emailUser && !error && (
        <EmailUserInfoCard
          toggleElite={toggleElite}
          paymentInfo={paymentInfo}
          emailUser={emailUser}
          spaces={spaces}
          deletedSpaces={deletedSpaces}
          loadSpaces={loadSpaces}
        />
      )}
    </Container>
  );
};

export default UserContainer;
