import { DependencyContainer } from '../../DependencyContainer';

const UNUM_API_KEY_INTERNAL = 'unum-assets-token-v2';
const UNUM_API_KEY = 'unum-api-key';

export interface AuthenticationServiceProtocol {
  signIn(email: string, password: string): Promise<void>;
  isAuthenticated(): Promise<boolean>;
}

export type ApiKeys = {
  unumApiKeyInternal: string;
  unumApiKey: string;
};

export class AuthenticationService implements AuthenticationServiceProtocol {
  private readonly storage: Storage;

  constructor(private readonly factory: DependencyContainer) {
    this.storage = window.localStorage;
  }

  async signIn(email: string, password: string): Promise<void> {
    const loginInternal = await this.factory.authenticationClient.makeSignInInternal(
      email,
      password,
    );
    const login = await this.factory.authenticationClient.makeSignInUnumApi(
      email,
      password,
    );
    this.cacheKeys({
      unumApiKeyInternal: loginInternal.token,
      unumApiKey: login.token,
    });
  }

  cacheKeys(keys: ApiKeys) {
    this.storage.setItem(UNUM_API_KEY, keys.unumApiKey);
    this.storage.setItem(UNUM_API_KEY_INTERNAL, keys.unumApiKeyInternal);
  }

  getCachedKeys(): ApiKeys {
    const unumApiKey = this.storage.getItem(UNUM_API_KEY);
    const unumApiKeyInternal = this.storage.getItem(UNUM_API_KEY_INTERNAL);
    return {
      unumApiKeyInternal: unumApiKeyInternal || '',
      unumApiKey: unumApiKey || '',
    };
  }

  async isAuthenticated() {
    const keys = this.getCachedKeys();
    return keys.unumApiKey !== '' && keys.unumApiKeyInternal !== '';
  }
}
