import React, { FunctionComponent } from 'react';
import MonetizationIcon from '@material-ui/icons/MonetizationOn';
import { Button, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { Team } from '../../dashboard/DashboardClient';
import { getUrlForRevenueCatGroupId } from '../../../urls';

export const TeamsExplorer: FunctionComponent<{ team: Team }> = ({ team }) => {
  const goToEntitlementsManagement = (groupId: string) => {
    window.open(getUrlForRevenueCatGroupId(groupId), '_blank');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <List>
          <ListItem>
            <ListItemText primary="Created at:" secondary={team.createdAt} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Updated at:" secondary={team.updatedAt} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Linked to user:"
              secondary={team.linkedToUser || 'none'}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Team id:" secondary={team._id} />
          </ListItem>
          {team.avatarUrl && (
            <ListItem>
              <ListItemText primary="Avatar URL:" secondary={team.avatarUrl} />
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          startIcon={<MonetizationIcon />}
          onClick={() => goToEntitlementsManagement(team._id)}
        >
          Manage entitlements
        </Button>
      </Grid>
    </Grid>
  );
};
