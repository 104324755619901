import { DependencyContainer } from '../../DependencyContainer';
import { EmailUser, Follower, User } from '../../types';
import { Spaces } from './DashboardClient';

export interface DashboardServiceProtocol {
  getUsersByFollowers(page: number): Promise<any>;
  getUserByEmail(email: string): Promise<any>;
  getUserSpaces(id: string): Promise<any>;
  getUserDeletedSpaces(id: string): Promise<any>;
  restoreDeletedSpace(spaceId: string): Promise<void>;
  changeSubscription(
    strategy: ChangeSubscriptionStrategy,
    userId: string,
  ): Promise<any>;
}

type ChangeSubscriptionStrategy = 'addSubscription' | 'cancelSubscription';

export class DashboardService implements DashboardServiceProtocol {
  private readonly storage: Storage;

  constructor(private readonly factory: DependencyContainer) {
    this.storage = window.localStorage;
  }

  async getUsersByFollowers(page: number = 1): Promise<Follower[]> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    return await this.factory.dashboardClient
      .withBasicToken(token)
      .getUsersByFollowers(page);
  }

  getUserByEmail(email: string): Promise<EmailUser> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    return this.factory.dashboardClient
      .withBasicToken(token)
      .getUserByEmail(email);
  }

  getUsersByInstagramHandle(instagramHandle: string): Promise<User[]> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    const users = this.factory.dashboardClient
      .withBasicToken(token)
      .getUsersByInstagramHandle(instagramHandle);
    return users;
  }

  getUserSpaces(id: string): Promise<Spaces> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    return this.factory.dashboardClient.withBasicToken(token).getUserSpaces(id);
  }

  getUserDeletedSpaces(id: string): Promise<Spaces> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    return this.factory.dashboardClient
      .withBasicToken(token)
      .getUserDeletedSpaces(id);
  }

  restoreDeletedSpace(spaceId: string): Promise<void> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    return this.factory.dashboardClient
      .withBasicToken(token)
      .restoreDeletedSpace(spaceId);
  }

  changeSubscription(
    strategy: ChangeSubscriptionStrategy,
    userId: string,
  ): Promise<any> {
    const token = this.factory.authenticationService.getCachedKeys()
      .unumApiKeyInternal;
    const client = this.factory.dashboardClient.withBasicToken(token);
    return client[strategy](userId);
  }
}
