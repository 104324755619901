import React, { FunctionComponent, useRef, useState } from 'react';
import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  fade,
  Grow,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
  Toolbar,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useGoToRoute } from '../utils/hooks/useGoToRoute';
import { Routes } from '../config';
import { useRecoilState } from 'recoil';
import { alertAtom, searchTextAtom } from '../state';
import { Alert } from '@material-ui/lab';
import { AlertType, User } from '../types';
import FilterListIcon from '@material-ui/icons/FilterList';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { DependencyContainer } from '../DependencyContainer';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
  searchFilterBox: {
    paddingLeft: 0,
    justifyContent: 'left',
    color: 'white',
  },
  searchFilterBoxIcon: {
    minWidth: '40px',
  },
  searchSpinner: {
    color: 'white',
    width: '20px !important',
    height: '20px !important',
  },
  foundUsersList: {
    maxHeight: '400px',
  },
}));

export enum SearchTypes {
  Email = 'email',
  InstagramHandle = 'instagram',
}
export type SearchType = { key: SearchTypes; label: string };
const availableSearchTypes: Array<SearchType> = [
  {
    key: SearchTypes.Email,
    label: 'by email',
  },
  {
    key: SearchTypes.InstagramHandle,
    label: 'by Instagram handle',
  },
];

const { dashboardService } = new DependencyContainer();

const AppLayoutContainer: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const goToRoute = useGoToRoute();
  const [searchText, setSearchText] = useRecoilState<string>(searchTextAtom);
  const [alert, setAlert] = useRecoilState<AlertType>(alertAtom);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [searchType, setSearchType] = useState<SearchTypes>(SearchTypes.Email);
  const [isSearchInProgress, setIsSearchInProgress] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [
    foundUsersByInstagramHandle,
    setFoundUsersByInstagramHandle,
  ] = useState<User[]>([]);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchType === SearchTypes.InstagramHandle) {
      setIsSearchInProgress(true);
      try {
        const users = await dashboardService.getUsersByInstagramHandle(
          searchText,
        );
        if (users.length === 0) {
          setAlert({
            type: 'error',
            message: 'User not found',
          });
        }
        setFoundUsersByInstagramHandle(users);
      } catch (error) {
        setFoundUsersByInstagramHandle([]);
        setAlert({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsSearchInProgress(false);
      }
      return;
    }
    handleUserSelected(searchText);
  };

  const filterBoxToggle = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const filterBoxClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setFilterBoxOpen(false);
  };

  const onSelectFilter = (searchType: SearchTypes) => {
    setSearchType(searchType);
    setFilterBoxOpen(false);
  };

  const closeAlert = () =>
    setAlert({
      type: 'info',
      message: '',
    });

  const getSelectedSearchType = (): SearchType | undefined => {
    return availableSearchTypes.find((t) => t.key === searchType);
  };

  const handleCloseDialog = () => {
    setFoundUsersByInstagramHandle([]);
  };

  const handleUserSelected = (email: string) => {
    goToRoute(Routes.User, { email });
    setFoundUsersByInstagramHandle([]);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography
            onClick={() => goToRoute(Routes.Dashboard)}
            variant="h6"
            className={classes.title}
          >
            User Explorer
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              {isSearchInProgress ? (
                <CircularProgress classes={{ root: classes.searchSpinner }} />
              ) : (
                <SearchIcon />
              )}
            </div>
            <form onSubmit={submitForm}>
              <InputBase
                placeholder={`Search ${getSelectedSearchType()?.label}`}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={searchText}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchText(event.target.value)
                }
                inputProps={{ 'aria-label': 'search' }}
              />
            </form>
          </div>
          <Button
            variant="text"
            ref={anchorRef}
            aria-controls={filterBoxOpen ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            classes={{
              root: classes.searchFilterBox,
            }}
            onClick={filterBoxToggle}
          >
            <FilterListIcon />
          </Button>
          <Popper
            open={filterBoxOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: 1,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={filterBoxClose}>
                    <MenuList autoFocusItem={filterBoxOpen} id="menu-list-grow">
                      {availableSearchTypes.map((type) => (
                        <MenuItem
                          key={type.key}
                          onClick={() => onSelectFilter(type.key)}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.searchFilterBoxIcon,
                            }}
                          >
                            {searchType === type.key ? (
                              <CheckCircle />
                            ) : (
                              <RadioButtonUnchecked />
                            )}
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {type.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button color="inherit" onClick={() => goToRoute(Routes.Dashboard)}>
            Followers rank
          </Button>
        </Toolbar>
      </AppBar>

      {children}

      <Snackbar
        onClose={closeAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!alert.message}
        autoHideDuration={3000}
      >
        <Alert severity={alert.type}>{alert.message}</Alert>
      </Snackbar>

      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="simple-dialog-title"
        open={Boolean(foundUsersByInstagramHandle.length)}
      >
        <DialogTitle id="simple-dialog-title">
          Found {foundUsersByInstagramHandle.length} users
        </DialogTitle>
        <DialogContent>
          <p>
            We found following users related to Instagram handle{' '}
            <i>{searchText}</i>
          </p>
          <List classes={{ root: classes.foundUsersList }}>
            {foundUsersByInstagramHandle.map((user) => (
              <ListItem
                button
                onClick={() => handleUserSelected(user.email!)}
                key={user._id}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.email}
                  secondary={`Plan: ${user.plan}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppLayoutContainer;
