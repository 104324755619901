import { ApiResponse, HttpClient } from '../../HttpClient';
import { AxiosResponse } from 'axios';
import {
  EmailUser,
  Follower,
  Modify,
  PaymentAvailablePlans,
  User,
  XOR,
} from '../../types';
import {
  getUrlForFollowers,
  getUrlForRestoreUserDeletedSpaces,
  getUrlForRetrieveEmailUser,
  getUrlForRetrieveUserByInstagramHandle,
  getUrlForUpdateTeam,
  getUrlForUserDeletedSpaces,
  getUrlForUserPayments,
  getUrlForUserSpaces,
} from '../../urls';

export interface DashboardClientProtocol {
  getUsersByFollowers(page: number): Promise<any>;
  getUserByEmail(email: string): Promise<any>;
  getUserSpaces(id: string): Promise<any>;
  cancelSubscription(id: string): Promise<any>;
  addSubscription(id: string): Promise<any>;
  restoreDeletedSpace(spaceId: string): Promise<void>;
}

export type SpaceResponse = {
  createdDate: {
    _seconds: number;
    _nanoseconds: number;
  };
  id: string;
  lastActiveDate: number;
  liveGridEnabled: boolean;
  owner: string;
  spaceType: string;
  timezone: string;
  title: string;
};

export type Team = {
  avatarUrl: string;
  createdAt: string;
  freeTrialEnd: string;
  name: string;
  updatedAt: string;
  linkedToUser?: string;
  __v: number;
  _id: string;
};

export type TeamWithFreeTrialEndUnix = Modify<Team, { freeTrialEnd: number }>;

export type Spaces = Array<
  XOR<{ user: any }, { group: Team }> & { spaces: SpaceResponse }
>;

export class DashboardClient extends HttpClient
  implements DashboardClientProtocol {
  async getUsersByFollowers(page: number = 1): Promise<Array<Follower>> {
    try {
      const users = await this.get<AxiosResponse<ApiResponse<Follower>>>(
        getUrlForFollowers(page),
      );
      return Promise.resolve((users.data as unknown) as Follower[]);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async getUserByEmail(email: string): Promise<EmailUser> {
    try {
      const user = await this.get<AxiosResponse<ApiResponse<EmailUser>>>(
        getUrlForRetrieveEmailUser(email),
      );
      if (!Object.keys(user.data).length) {
        return Promise.reject();
      }
      return Promise.resolve((user.data as unknown) as EmailUser);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async getUsersByInstagramHandle(instagramHandle: string): Promise<User[]> {
    const response = await this.get<AxiosResponse<ApiResponse<User[]>>>(
      getUrlForRetrieveUserByInstagramHandle(instagramHandle),
    );
    return response.data.data;
  }

  async getUserSpaces(id: string): Promise<Spaces> {
    try {
      const spaces = await this.get<AxiosResponse<ApiResponse<Spaces>>>(
        getUrlForUserSpaces(id),
      );
      return spaces.data.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async getUserDeletedSpaces(id: string): Promise<Spaces> {
    try {
      const spaces = await this.get<AxiosResponse<ApiResponse<Spaces>>>(
        getUrlForUserDeletedSpaces(id),
      );
      return spaces.data.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async updateTeam(
    teamId: string,
    payload: Partial<TeamWithFreeTrialEndUnix>,
  ): Promise<void> {
    await this.patch(getUrlForUpdateTeam(teamId), payload);
  }

  async restoreDeletedSpace(spaceId: string): Promise<void> {
    try {
      await this.post<AxiosResponse<ApiResponse<any>>>(
        getUrlForRestoreUserDeletedSpaces(),
        {
          spaceId,
        },
      );
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async addSubscription(id: string): Promise<any> {
    try {
      const response = await this.post<AxiosResponse<ApiResponse<any>>>(
        getUrlForUserPayments(id),
        {
          purchased_via: 'Lifetime',
          plan: PaymentAvailablePlans.Elite,
          subscription_status: 'Active',
        },
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async cancelSubscription(id: string): Promise<any> {
    try {
      const response = await this.post<AxiosResponse<ApiResponse<any>>>(
        getUrlForUserPayments(id),
        {
          purchased_via: 'Lifetime',
          plan: PaymentAvailablePlans.Free,
          subscription_status: 'Inactive',
        },
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}
