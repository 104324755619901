import { useState, useEffect } from 'react';

export const useBeforeFirstRender = (f: CallableFunction) => {
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => setHasRendered(true), []);

  if (!hasRendered) {
    f();
  }
};
