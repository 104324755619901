import { apiUrls } from './config';

export const getUrlForUserSpaces = (userId: string) =>
  `${apiUrls.baseUrl}/v1/explorer/spaces?userId=${userId}`;

export const getUrlForUserDeletedSpaces = (userId: string) =>
  `${apiUrls.baseUrl}/v1/explorer/spaces/deleted?userId=${userId}`;

export const getUrlForUpdateTeam = (teamId: string) =>
  `${apiUrls.baseUrl}/v1/groups/${teamId}`;

export const getUrlForRestoreUserDeletedSpaces = () =>
  `${apiUrls.baseUrl}/v1/explorer/spaces/deleted/restore`;

export const getUrlForUserPayments = (userId: string) =>
  `${apiUrls.baseUrl}/v1/users/${userId}/payments`;

export const getUrlForRetrieveEmailUser = (email: string) =>
  `${apiUrls.unumApiUrl}/v1/emails?email=${encodeURIComponent(email)}`;

export const getUrlForRetrieveUserByInstagramHandle = (handle: string) =>
  `${apiUrls.baseUrl}/v1/users?instagram=${encodeURIComponent(handle)}`;

export const getUrlForFollowers = (page: number) =>
  `${apiUrls.unumApiUrl}/user-explorer/find/followers?page=${page}`;

export const getUrlForSignInInternal = () =>
  `${apiUrls.baseUrl}/v1/login/internal`;

export const getUrlForDeleteUser = (userId: string) =>
  `${apiUrls.baseUrl}/v1/users/${userId}`;

export const getUrlForSignInUnumApi = () => `${apiUrls.baseUrl}/v1/login`;
export const getUrlForRevenueCatGroupId = (groupId: string) =>
  `https://app.revenuecat.com/customers/${process.env.REACT_APP_REVENUE_CAT_APP_ID}/${groupId}`;
