import { ApiResponse, HttpClient } from '../../HttpClient';
import { AxiosResponse } from 'axios';
import { getUrlForSignInInternal, getUrlForSignInUnumApi } from '../../urls';

export interface AuthenticationClientProtocol {
  makeSignInInternal(
    email: string,
    password: string,
  ): Promise<SignInGenericResponse>;
  makeSignInUnumApi(
    email: string,
    password: string,
  ): Promise<SignInGenericResponse>;
}

export type SignInGenericResponse = {
  token: string;
};

export class AuthenticationClient extends HttpClient
  implements AuthenticationClientProtocol {
  async makeSignInInternal(
    email: string,
    password: string,
  ): Promise<SignInGenericResponse> {
    try {
      const loginInternal = await this.post<AxiosResponse<ApiResponse<any>>>(
        getUrlForSignInInternal(),
        {
          email,
          password,
          type: 'web',
        },
      );
      return Promise.resolve({
        token: loginInternal.data.data.authToken,
      });
    } catch (error) {
      console.log(error);
      return Promise.reject(error.response);
    }
  }

  async makeSignInUnumApi(
    email: string,
    password: string,
  ): Promise<SignInGenericResponse> {
    try {
      const loginInternal = await this.post<AxiosResponse<ApiResponse<any>>>(
        getUrlForSignInUnumApi(),
        {
          email,
          password,
          type: 'web',
        },
      );
      return Promise.resolve({
        token: loginInternal.data.data.authToken,
      });
    } catch (error) {
      console.log(error);
      return Promise.reject(error.response);
    }
  }
}
