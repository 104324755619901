import { FunctionComponent, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { locationAtom } from '../../state';
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { Routes } from '../../config';
import { useHistory } from 'react-router';

const ForLoggedIn: FunctionComponent<any> = ({ children }) => {
  const history = useHistory();
  const [location] = useRecoilState<any>(locationAtom);
  const isAuthenticated = useIsAuthenticated({
    error: () => history.push(Routes.SignIn),
  });

  useEffect(() => {
    isAuthenticated.callable();
  }, [location, isAuthenticated]);

  return isAuthenticated.value ? children : null;
};

export default ForLoggedIn;
