import { AxiosRequestConfig, Method } from 'axios';
import * as https from 'https';
import { DependencyContainer } from './DependencyContainer';
import axiosInstance from './axiosSetup';

export interface HttpClientProtocol {
  get(endpoint: string): Promise<any>;
  post(endpoint: string, data?: any): Promise<any>;
}

export type ApiResponse<T> = {
  status: string;
  data: T;
};

export class HttpClient implements HttpClientProtocol {
  protected token: string | null;
  constructor(protected readonly factory: DependencyContainer) {
    this.factory = factory;
    this.token = null;
  }

  async get<T>(endpoint: string): Promise<T> {
    const config = await this.getAxiosRequestConfig('GET', endpoint);
    return axiosInstance.get(endpoint, config as AxiosRequestConfig);
  }

  async post<T>(endpoint: string, data: any): Promise<T> {
    const config = await this.getAxiosRequestConfig('POST', endpoint);
    return await axiosInstance.post(endpoint, data, config);
  }

  async patch<T>(endpoint: string, data: any): Promise<T> {
    const config = await this.getAxiosRequestConfig('PATCH', endpoint);
    return await axiosInstance.patch(endpoint, data, config);
  }

  async delete<T>(endpoint: string): Promise<T> {
    const config = await this.getAxiosRequestConfig('DELETE', endpoint);
    return await axiosInstance.delete(endpoint, config as AxiosRequestConfig);
  }

  withBasicToken(token: string) {
    if (token === null) {
      return this;
    }
    this.token = token;
    return this;
  }

  private async getAxiosRequestConfig(
    method: Method,
    endpoint: string,
  ): Promise<AxiosRequestConfig> {
    const headers =
      this.token !== null
        ? {
            Authorization: this.token,
          }
        : undefined;
    const config: AxiosRequestConfig = {
      url: endpoint,
      method,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      headers,
    };
    return Promise.resolve(config);
  }
}
