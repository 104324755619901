import React, { FunctionComponent, useState } from 'react';
import { useIsAuthenticated } from '../../utils/hooks/useIsAuthenticated';
import { Routes } from '../../config';
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useGoToRoute } from '../../utils/hooks/useGoToRoute';
import { DependencyContainer } from '../../DependencyContainer';
import { AuthenticationServiceProtocol } from './AuthenticationService';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const service: AuthenticationServiceProtocol = new DependencyContainer()
  .authenticationService;

const LoginContainer: FunctionComponent = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const goToRoute = useGoToRoute();
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated({
    success: () => goToRoute(Routes.Dashboard),
  });

  if (isAuthenticated.value) {
    return null;
  }

  const makeLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await service.signIn(username, password);
      goToRoute(Routes.Dashboard);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            className={classes.form}
            onSubmit={(e) => makeLogin(e)}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                setUsername(event.target.value)
              }
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading && <CircularProgress />}
              {loading ? 'Signing In...' : 'Sign In'}
            </Button>
            <Box mt={5}></Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginContainer;
