import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { UserGroup } from '../UserService';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';

export type UserCardDialogsProps = {
  visibilityState: {
    deleteUserConfirmationOpened: boolean;
    deleteError: boolean;
    deleteUserSuccessConfirmationOpened: boolean;
  };
  closingFunctions: {
    closeDialog: () => void;
    closeErrorDialog: () => void;
    closeSuccessDialog: () => void;
  };
  deleteError: any;
  deleteAction: () => void;
};

export const UserCardDialogs: FunctionComponent<UserCardDialogsProps> = ({
  visibilityState,
  closingFunctions,
  deleteError,
  deleteAction,
}) => {
  return (
    <>
      <Dialog
        open={visibilityState.deleteUserConfirmationOpened}
        onClose={closingFunctions.closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm deleting account
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>If you delete this account, all related data, such as:</p>
            <ul>
              <li>spaces</li>
              <li>storyboards</li>
              <li>user's payment data</li>
              <li>integrations</li>
            </ul>
            <p>will be deleted as well.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closingFunctions.closeDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={() => deleteAction()}>
            I know the consequences, delete.
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={visibilityState.deleteError}
        onClose={closingFunctions.closeErrorDialog}
        aria-labelledby="alert-error-dialog-title"
        aria-describedby="alert-error-dialog-description"
      >
        <DialogTitle id="alert-error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-error-dialog-description">
            {deleteError &&
            deleteError.response &&
            deleteError.response.data.code === 409 ? (
              <>
                <p>This user is owner of groups:</p>
                {deleteError?.response?.data?.data?.message?.groups &&
                  deleteError?.response?.data?.data?.message?.groups.map(
                    (group: UserGroup) => <p>{group.name}</p>,
                  )}
              </>
            ) : (
              <p>Something went wrong</p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closingFunctions.closeErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        open={visibilityState.deleteUserSuccessConfirmationOpened}
        onClick={closingFunctions.closeSuccessDialog}
      >
        <Dialog
          open={visibilityState.deleteUserSuccessConfirmationOpened}
          onClose={closingFunctions.closeSuccessDialog}
          aria-labelledby="alert-success-dialog-title"
          aria-describedby="alert-success-dialog-description"
        >
          <DialogTitle id="alert-success-dialog-title">Success</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-success-dialog-description">
              <p>User has been deleted.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closingFunctions.closeSuccessDialog}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
    </>
  );
};
