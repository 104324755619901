export enum Routes {
  SignIn = '/',
  Dashboard = '/dashboard',
  User = '/user/:email?',
}

export const apiUrls = {
  baseUrl: process.env.REACT_APP_API_UNUM_URL,
  unumApiUrl: process.env.REACT_APP_API_SPRINT_URL,
};

export const paymentProcessors = ['stripe', 'apple', 'android'];

export const dateFormat = 'MMMM DD YYYY HH:mm:ss';
export const dateFormatShort = 'MMM DD YYYY HH:mm';
