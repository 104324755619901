import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { SpacesExplorerPrimaryData } from './SpacesExplorerPrimaryData';
import { SpaceResponse } from '../../dashboard/DashboardClient';

export const SpacesExplorer: FunctionComponent<{
  space: SpaceResponse;
  teamName?: string;
}> = ({ space, teamName }) => {
  return (
    <Grid container spacing={3}>
      <SpacesExplorerPrimaryData space={space} teamName={teamName} />
    </Grid>
  );
};
