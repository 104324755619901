import { useState } from 'react';
import { DependencyContainer } from '../../DependencyContainer';
import { useBeforeFirstRender } from './useBeforeFirstRender';

const service = new DependencyContainer().authenticationService;

export const useIsAuthenticated = (
  args: UseIsAuthenticatedArgs,
): UseIsAuthenticated => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const callable = async () => {
    const authenticated = await service.isAuthenticated();
    if (authenticated) {
      args.success && args.success();
    } else {
      args.error && args.error();
    }
    setUserAuthenticated(authenticated);
  };

  useBeforeFirstRender(() => {
    callable();
  });

  return {
    callable,
    value: userAuthenticated,
  };
};

export interface UseIsAuthenticated {
  callable: CallableFunction;
  value: boolean;
}

export interface UseIsAuthenticatedArgs {
  success?: CallableFunction;
  error?: CallableFunction;
}
