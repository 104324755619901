import { Routes } from '../../config';
import { useHistory } from 'react-router';

export const useGoToRoute = () => {
  const history = useHistory();

  return (routeName: Routes, params?: any) => {
    const allParams = Object.keys(params || {});
    const route =
      params !== undefined
        ? allParams.reduce(
            (acc: string, param: string) =>
              acc.replace(`:${param}`, params[param]),
            routeName,
          )
        : routeName;
    history.push(route);
  };
};
