import React, { FunctionComponent } from 'react';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Session } from '../../../types';

type SessionExplorerProps = {
  sessions: Session[];
};

export const SessionExplorer: FunctionComponent<SessionExplorerProps> = ({
  sessions,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Session ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Last Active Date</TableCell>
            <TableCell>Push Token</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session) => (
            <TableRow key={session._id}>
              <TableCell component="th" scope="row">
                {session._id}
              </TableCell>
              <TableCell>
                <Chip label={session.type} color="secondary" />
              </TableCell>
              <TableCell>{session.createdDate}</TableCell>
              <TableCell>{session.lastActiveDate}</TableCell>
              <TableCell>{session.pushToken}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
