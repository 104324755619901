import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  ListItemSecondaryAction,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FaceIcon from '@material-ui/icons/FaceRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AlertType, EmailUser } from '../../../types';
import { SpacesExplorer } from './SpacesExplorer';
import { SessionExplorer } from './SessionExplorer';
import { DependencyContainer } from '../../../DependencyContainer';
import { UserCardDialogs } from './UserCardDialogs';
import { SpaceResponse, Team } from '../../dashboard/DashboardClient';
import { TeamsExplorer } from './TeamsExplorer';
import { useSetRecoilState } from 'recoil';
import { alertAtom } from '../../../state';

const { userService, dashboardService } = new DependencyContainer();

const TabPanel: FunctionComponent<any> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  foldersTab: {
    display: 'flex',
    flexDirection: 'row',
  },
  foldersTabChip: {
    marginLeft: '10px',
  },
}));

export type EmailUserInfoCardProps = {
  emailUser: EmailUser;
  spaces: any[];
  deletedSpaces: any[];
  paymentInfo: any;
  toggleElite: CallableFunction;
  loadSpaces: (emailUser: EmailUser) => void;
};

export const EmailUserInfoCard: FunctionComponent<EmailUserInfoCardProps> = ({
  emailUser,
  spaces,
  deletedSpaces,
  loadSpaces,
}) => {
  const parseDate = (input: any) =>
    new Date(input).toString().split(' ').slice(0, 4).join(' ');
  const [value, setValue] = useState(0);
  const [
    deleteUserConfirmationOpened,
    setDeleteUserConfirmationOpened,
  ] = useState(false);
  const [
    deleteUserSuccessConfirmationOpened,
    setDeleteUserSuccessConfirmationOpened,
  ] = useState(false);
  const [deleteError, setDeleteError] = useState();
  const setAlert = useSetRecoilState<AlertType>(alertAtom);
  const classes = useStyles();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const teams = spaces
    .filter((account) => account.group !== undefined)
    .map((result) => result.group);
  const countSpaces = spaces
    ? spaces.reduce((acc, account) => acc + account.spaces.length, 0)
    : 0;
  const countDeletedSpaces = deletedSpaces
    ? deletedSpaces.reduce((acc, account) => acc + account.spaces.length, 0)
    : 0;
  const countTeams = teams ? teams.length : 0;
  const countSessions = emailUser.sessions ? emailUser.sessions.length : 0;

  const spacesTabLabel = (
    <span className={classes.foldersTab}>
      Spaces
      <Chip
        className={classes.foldersTabChip}
        size="small"
        label={countSpaces}
      />
    </span>
  );
  const deletedSpacesTabLabel = (
    <span className={classes.foldersTab}>
      Deleted spaces
      <Chip
        className={classes.foldersTabChip}
        size="small"
        label={countDeletedSpaces}
      />
    </span>
  );
  const teamsTabLabel = (
    <span className={classes.foldersTab}>
      Teams
      <Chip
        className={classes.foldersTabChip}
        size="small"
        label={countTeams}
      />
    </span>
  );
  const sessionsTabLabel = (
    <span className={classes.foldersTab}>
      Sessions
      <Chip
        className={classes.foldersTabChip}
        size="small"
        label={countSessions}
      />
    </span>
  );

  const closeDialog = useCallback(() => {
    setDeleteUserConfirmationOpened(false);
  }, []);

  const closeErrorDialog = useCallback(() => {
    setDeleteError(undefined);
  }, []);

  const closeSuccessDialog = useCallback(() => {
    setDeleteUserSuccessConfirmationOpened(false);
    window.location.href = '/';
  }, []);

  const deleteUser = useCallback(() => {
    userService
      .delete(emailUser._id)
      .then(() => {
        setDeleteUserConfirmationOpened(false);
        setDeleteUserSuccessConfirmationOpened(true);
      })
      .catch((error) => {
        setDeleteUserConfirmationOpened(false);
        setDeleteError(error);
      });
  }, [emailUser._id]);

  const restoreSpace = (spaceId: string, spaceTitle: string) => {
    try {
      dashboardService.restoreDeletedSpace(spaceId);
      setAlert({
        type: 'success',
        message: `Space ${spaceTitle} has been restored.`,
      });
      setTimeout(() => {
        loadSpaces(emailUser);
      }, 400);
    } catch (error) {
      setAlert({
        type: 'error',
        message: `Something went wrong`,
      });
    }
  };

  return (
    <Grid container style={{ marginTop: '10px' }} spacing={3}>
      <Grid item xs={3}>
        <Card style={{ position: 'sticky', top: '10px' }}>
          <CardContent>
            <List>
              <ListItem>
                <Avatar src={emailUser.avatarUrl} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="ID"
                  secondary={emailUser._id || 'no data...'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Full name"
                  secondary={emailUser.fullName || 'no data...'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="E-mail"
                  secondary={emailUser.email || 'no data...'}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Last active date"
                  secondary={
                    parseDate(emailUser.lastActiveDate) || 'no data...'
                  }
                />
              </ListItem>
            </List>
            <Typography gutterBottom variant="h5" component="h2">
              Danger zone
            </Typography>
            <Button
              onClick={() => setDeleteUserConfirmationOpened(true)}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
            >
              Delete this user
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <Card>
          <CardContent>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label={sessionsTabLabel} />
                <Tab label={spacesTabLabel} />
                <Tab label={deletedSpacesTabLabel} />
                <Tab label={teamsTabLabel} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <SessionExplorer sessions={emailUser.sessions || []} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {spaces &&
                spaces.length &&
                spaces.map((result) => {
                  const teamBadge = result.group ? (
                    <Chip
                      label="Team"
                      color="secondary"
                      style={{ marginLeft: 'auto' }}
                    />
                  ) : (
                    <Chip
                      label="Personal"
                      color="primary"
                      style={{ marginLeft: 'auto' }}
                    />
                  );
                  return result.spaces.map((space: SpaceResponse) => (
                    <ExpansionPanel key={space.id}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        id={`space${space.id}`}
                      >
                        <Typography
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                          }}
                        >
                          {space.title ? space.title : 'Space'} {space.id}
                          {teamBadge}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <SpacesExplorer
                          space={space}
                          teamName={result?.group?.name}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ));
                })}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <List>
                {deletedSpaces &&
                  deletedSpaces.length &&
                  deletedSpaces.map((result) => {
                    return result.spaces.map((space: SpaceResponse) => (
                      <ListItem>
                        <ListItemText>
                          {space.title ? space.title : 'Space'} {space.id}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Button
                            variant="contained"
                            color="default"
                            onClick={() => restoreSpace(space.id, space.title)}
                          >
                            Restore
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ));
                  })}
              </List>
            </TabPanel>
            <TabPanel value={value} index={3}>
              {teams &&
                teams.length &&
                teams.map((team: Team) => (
                  <ExpansionPanel key={team._id}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      id={`team${team._id}`}
                    >
                      <Typography>
                        {team.name ? team.name : `Team ${team._id}`}
                      </Typography>
                      {team.linkedToUser === emailUser._id && (
                        <Chip
                          style={{ marginLeft: 6 }}
                          icon={<FaceIcon />}
                          label="Linked to this user"
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <TeamsExplorer team={team} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>

      <UserCardDialogs
        closingFunctions={{
          closeDialog,
          closeErrorDialog,
          closeSuccessDialog,
        }}
        visibilityState={{
          deleteError: Boolean(deleteError),
          deleteUserConfirmationOpened,
          deleteUserSuccessConfirmationOpened,
        }}
        deleteAction={deleteUser}
        deleteError={deleteError}
      />
    </Grid>
  );
};
