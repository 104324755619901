import React, { FunctionComponent } from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { SpaceResponse } from '../../dashboard/DashboardClient';
import moment from 'moment';
import { dateFormat } from '../../../config';

export type SpacesExplorerPrimaryDataProps = {
  space: SpaceResponse;
  teamName?: string;
};

export const SpacesExplorerPrimaryData: FunctionComponent<SpacesExplorerPrimaryDataProps> = ({
  space,
  teamName,
}) => {
  const createdDate = space?.createdDate?._seconds;
  const createdDateFormatted = createdDate
    ? moment.unix(createdDate).utc().format(dateFormat)
    : '';
  return (
    <Grid item xs={4}>
      <h4>Primary data</h4>
      <List>
        <ListItem>
          <ListItemText primary="Space type" secondary={space.spaceType} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Title" secondary={space.title || 'n/a'} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Created date"
            secondary={createdDateFormatted}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Timezone" secondary={space.timezone} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Space ID" secondary={space.id} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Owner ID" secondary={space.owner} />
        </ListItem>
        {teamName && (
          <ListItem>
            <ListItemText primary="Belongs to the team" secondary={teamName} />
          </ListItem>
        )}
        <ListItem>
          <ListItemText
            primary="Live grid enabled"
            secondary={space.liveGridEnabled ? 'yes' : 'no'}
          />
        </ListItem>
      </List>
    </Grid>
  );
};
