import { atom } from 'recoil';
import { AlertType } from './types';

export const locationAtom = atom({
  key: 'locationAtom',
  default: '',
});
export const searchTextAtom = atom<string>({
  key: 'searchTextAtom',
  default: '',
});
export const alertAtom = atom<AlertType>({
  key: 'alertAtom',
  default: {
    type: 'info',
    message: '',
  },
});
