import { ApiResponse, HttpClient } from '../../HttpClient';
import { AxiosResponse } from 'axios';
import { DeleteUserConflictResponse } from './UserService';
import { getUrlForDeleteUser } from '../../urls';

export class UserClient extends HttpClient {
  async deleteUser(
    userId: string,
  ): Promise<AxiosResponse<void | ApiResponse<DeleteUserConflictResponse>>> {
    return this.delete(getUrlForDeleteUser(userId));
  }
}
