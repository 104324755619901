export type StatePaymentInfo = {
  purchasedVia: string;
  plan: string;
};

export enum PaymentAvailablePlans {
  Free = 'free',
  Elite = 'elite',
}

export type User = {
  _id: string;
  lastActiveDate: string;
  fullName?: string;
  email?: string;
  avatarUrl?: string;
  plan?: 'free' | 'elite';
};
export type SubscriptionSwitchStatus = {
  label: string;
  disabled: boolean;
};

export type AlertType = {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
};

export type SubscriptionStatus = 'Active' | 'Inactive';

export type Draft = {
  _id: string;
  isDefault: boolean;
  createdDate: string;
};
export type SessionType = 'ios' | 'android' | 'web';
export type Session = {
  _id: string;
  createdDate: string;
  lastActiveDate: string;
  type: SessionType;
  pushToken?: string;
};

export type Follower = User & {
  username: string;
  instagramId: string;
  instagramToken: string;
  subscription_status: SubscriptionStatus;
  __kind: string;
  isFreeUser: boolean;
  plan: PaymentAvailablePlans;
  UTCOffset: number;
  liveGridEnabled: boolean;
  numTiles: number;
  isDraftEnabled: boolean;
  drafts: Draft[];
  notificationSettings: any[];
  sessions: Session[];
  createdDate: string;
  is_verified_insta: boolean;
  numMutual: number;
  numFollowing: number;
  numFollowers: number;
  numPosts: number;
  __v: number;
  receiptData: string;
  subscription_via: string;
  team?: any[];
};

export type EmailUserPaymentInfo = {
  _id: string;
  createdDate: string;
  plan: PaymentAvailablePlans;
  purchased_via: 'Lifetime' | 'apple' | 'stripe' | 'android';
  subscription_status: 'Active' | 'Inactive';
  user: string;
  __v: number;
};

export type EmailUser = User & {
  sessions?: Session[];
  email: string;
  payment_info: EmailUserPaymentInfo[];
};

export type SpaceDraft = Draft;

export type BasicDisplayIntegration = {
  _id: string;
  accountId: string;
  user: string;
  __v: number;
  avatarImageUrl: string | null;
  modelType: 'BasicDisplayIntegration';
  thirdParty: string;
  title?: string;
};

export type FacebookIntegration = BasicDisplayIntegration & {
  modelType: 'FacebookIntegration';
};

export type MinimalSpace = {
  _id: string;
  __kind: 'Story' | 'Grid';
  owner: User;
  createdDate: string;
  timezone: string;
  title?: string;
  drafts?: SpaceDraft[];
  basicDisplayIntegration?: BasicDisplayIntegration;
  facebookIntegration?: FacebookIntegration;
  liveGridEnabled?: boolean;
  isDraftEnabled?: boolean;
};
export type StorySpace = MinimalSpace & {
  __kind: 'Story';
};

export type GridSpace = MinimalSpace & {
  __kind: 'Grid';
};

export type Space = StorySpace | GridSpace;

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
export type Modify<T, R> = Omit<T, keyof R> & R;
