import { AuthenticationService } from './features/login/AuthenticationService';
import { AuthenticationClient } from './features/login/AuthenticationClient';
import { DashboardService } from './features/dashboard/DashboardService';
import { DashboardClient } from './features/dashboard/DashboardClient';
import { UserService } from './features/user/UserService';
import { UserClient } from './features/user/UserClient';

export class DependencyContainer {
  // Services
  authenticationService = new AuthenticationService(this);
  dashboardService = new DashboardService(this);
  userService = new UserService(this);

  // Clients
  authenticationClient = new AuthenticationClient(this);
  dashboardClient = new DashboardClient(this);
  userClient = new UserClient(this);
}
