import { DependencyContainer } from '../../DependencyContainer';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '../../HttpClient';

export type UserGroup = {
  _id: string;
  freeTrialEnd: string;
  name: string;
  avatarUrl: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

export type DeleteUserConflictResponse = {
  message: {
    groups: UserGroup[];
    reason: string;
  };
};

export class UserService {
  constructor(private readonly factory: DependencyContainer) {}

  async delete(
    userId: string,
  ): Promise<AxiosResponse<void | ApiResponse<DeleteUserConflictResponse>>> {
    const token = this.factory.authenticationService.getCachedKeys().unumApiKey;
    return await this.factory.userClient
      .withBasicToken(token)
      .deleteUser(userId);
  }
}
