import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Avatar,
  Backdrop,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { DashboardServiceProtocol } from './DashboardService';
import { DependencyContainer } from '../../DependencyContainer';
import { useSetRecoilState } from 'recoil';
import { searchTextAtom } from '../../state';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useGoToRoute } from '../../utils/hooks/useGoToRoute';
import { Routes } from '../../config';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  tableContainer: {
    marginTop: '30px',
  },
  table: {
    minWidth: 650,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },

  card: {
    display: 'flex',
    minWidth: 275,
    backgroundColor: '#fff',
    marginTop: '20px',
  },
  cardBullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  cardTitle: {
    fontSize: 14,
  },
  avatar: {
    width: 151,
    minHeight: '100px',
  },

  badges: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    width: '400px',
    margin: '20px auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const service: DashboardServiceProtocol = new DependencyContainer()
  .dashboardService;

type User = {
  _id: string;
  username: string;
  fullName: string;
  numFollowers: number;
  avatarUrl?: string;
  email?: string;
};

const DashboardContainer: FunctionComponent = () => {
  const classes = useStyles();
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const setSearchText = useSetRecoilState(searchTextAtom);
  const goToRoute = useGoToRoute();

  const openUserCard = (email: string) => goToRoute(Routes.User, { email });

  useEffect(() => {
    setLoading(true);
    service
      .getUsersByFollowers(page)
      .then((u: any) => {
        setUsers(u);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  }, [page]);

  useEffect(() => {
    setSearchText('');
  }, []);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    const pageNumber = parseInt(value);
    if (Number.isInteger(pageNumber)) {
      setPage(parseInt(e.target.value));
    }
  };

  const openInNewTab = (url: string) => {
    const win = window.open(url, '_blank');
    win?.focus();
  };

  return (
    <>
      <Container maxWidth="xl">
        <div className={classes.pagination}>
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button
              disabled={loading || page <= 1}
              type="button"
              variant="contained"
              onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
            >
              <NavigateBeforeIcon />
            </Button>
            <TextField
              id="filled-basic"
              label="Page"
              name="page"
              variant="outlined"
              onInput={onInput}
              value={page}
            />
            <Button
              disabled={loading}
              type="button"
              variant="contained"
              onClick={() => setPage((p) => p + 1)}
            >
              <NavigateNextIcon />
            </Button>
          </ButtonGroup>
        </div>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {!loading &&
          users &&
          users.map((user: User) => (
            <Card key={user._id} className={classes.card}>
              <CardMedia className={classes.avatar} title={user.fullName}>
                <Avatar
                  style={{ width: '100%', height: '100%' }}
                  variant="square"
                  src={user.avatarUrl}
                />
              </CardMedia>
              <div className={classes.details}>
                <CardContent>
                  <Typography
                    variant="h5"
                    className={classes.title}
                    color="textPrimary"
                    gutterBottom
                  >
                    {user.fullName}
                  </Typography>
                  <Typography variant="h5" component="h2"></Typography>
                  <Typography color="textSecondary">id: {user._id}</Typography>
                  <div className={classes.badges}>
                    <Chip
                      label={user.username}
                      clickable
                      color="primary"
                      onClick={() =>
                        openInNewTab(
                          `https://www.instagram.com/${user.username}`,
                        )
                      }
                    />
                    <Chip label={`Followers: ${user.numFollowers}`} />
                  </div>
                </CardContent>
                {user.email !== undefined && (
                  <CardActions>
                    <Button
                      size="large"
                      onClick={() => openUserCard(user.email as string)}
                    >
                      SHOW USER CARD
                    </Button>
                  </CardActions>
                )}
              </div>
            </Card>
          ))}
      </Container>
    </>
  );
};

export default DashboardContainer;
