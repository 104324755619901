import React, { FunctionComponent, useEffect } from 'react';
import './App.css';
import { RecoilRoot, useRecoilState } from 'recoil';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory,
} from 'react-router-dom';
import LoginContainer from './features/login/LoginContainer';
import { locationAtom } from './state';
import { Routes } from './config';
import ForLoggedIn from './utils/components/ForLoggedIn';
import AppLayoutContainer from './features/AppLayoutContainer';
import DashboardContainer from './features/dashboard/DashboardContainer';
import './globalStyles.scss';
import UserContainer from './features/user/UserContainer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export const AppWrapper = withRouter((props: any) => {
  const history = useHistory();
  const [, setLocation] = useRecoilState<any>(locationAtom);

  useEffect(
    () =>
      history.listen(() => {
        setLocation(history.location);
      }),
    [history, setLocation],
  );

  return props.children;
});

const App: FunctionComponent = () => {
  return (
    <RecoilRoot>
      <Router>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppWrapper>
            <Switch>
              <Route path={Routes.Dashboard}>
                <ForLoggedIn>
                  <AppLayoutContainer>
                    <DashboardContainer />
                  </AppLayoutContainer>
                </ForLoggedIn>
              </Route>
              <Route path={Routes.User}>
                <ForLoggedIn>
                  <AppLayoutContainer>
                    <UserContainer />
                  </AppLayoutContainer>
                </ForLoggedIn>
              </Route>
              <Route path={Routes.SignIn}>
                <LoginContainer />
              </Route>
            </Switch>
          </AppWrapper>
        </MuiPickersUtilsProvider>
      </Router>
    </RecoilRoot>
  );
};

export default App;
